.App {
  text-align: center;
}
#home {
  background-image:
  linear-gradient(to bottom, rgba(39, 39, 39, 0.4), rgba(0, 0, 0, 0.5)),
  url('../src/images/field.jpg');
  width: 100%;
  height: 100vh;
  margin-top: -0px;
  background-size: cover;
}

html {
  scroll-behavior: smooth;
}

.shiftDown {
  margin-top: 60px;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

#matchups {
  min-height: 100vh;
}

.helmets {
  width: 100%;
  height: 35vh;
  margin-top: -0px;
  background-size: cover;
  mask-image:
  linear-gradient(to bottom, rgb(0, 0, 0, 0.8), rgba(0, 0, 0, 0.87));

}

.field {
  width: 100%;
  height: 40vh;
  margin-top: -0px;
  background-size: cover;
  mask-image:
  linear-gradient(to top, rgba(0, 0, 0, 0.98), rgba(0, 0, 0, 0.85));

}